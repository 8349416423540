import React from "react"

// Libraries
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import PageHero from "components/page-hero/"
import Container from "components/container/"
import LayoutPrimary from "layouts/layout-primary"
import { colors } from "utils/variables"
import Seo from "components/seo"

const StyledPolicyPage = styled.div`
  display: block;

  ${Container} {
    max-width: 768px;
  }

  .policy__content {
    padding: 60px 0;

    ${breakpoint.medium`
      padding: 72px 0;
    `}

    .last-updated {
      margin-bottom: 60px;

      ${breakpoint.medium`
        margin-bottom: 72px;
      `}
    }

    h2,
    h3 {
      margin-bottom: 16px;
      font-weight: 400;

      b {
        font-weight: 400;
      }
    }

    p {
      margin: 1em 0;

      &.paragraph--small {
        & + p:not(.paragraph--small) {
          margin-top: 32px;

          ${breakpoint.medium`
          margin-top: 48px;
        `}
        }
      }

      b {
        font-weight: 500;
      }

      & + h2 {
        margin-top: 60px;

        ${breakpoint.medium`
          margin-top: 72px;
        `}
      }

      & + h3 {
        margin-top: 32px;

        ${breakpoint.medium`
          margin-top: 48px;
        `}
      }

      .strikethrough {
        color: ${colors.indiblue};
        text-decoration: none;

        &:hover {
          color: ${colors.midnight};
        }

        &::before {
          background-color: ${colors.supernova};
        }

        u {
          text-decoration: none;
        }
      }
    }
  }
`

const PolicyPage = props => {
  const {
    data: {
      contentfulPolicyPages: { updatedAt, title, content },
    },
  } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_5]: (node, children) => (
        <p className="font-weight--500">{children}</p>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="paragraph--small">{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.uri}
            className="strikethrough"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )
      },
    },
  }

  return (
    <LayoutPrimary>
      <Seo title={`${title} - Indicius Design Agency`} />
      <StyledPolicyPage>
        <PageHero
          title="Privacy Policy."
          highlight="What personal data we collect from visitors and why."
        />

        <div className="policy__content">
          <Container>
            <p className="paragraph--small">Last Updated: {updatedAt}.</p>
            {renderRichText(content, renderOptions)}
          </Container>
        </div>
      </StyledPolicyPage>
    </LayoutPrimary>
  )
}

PolicyPage.propTypes = {
  data: PropTypes.instanceOf(PropTypes.object).isRequired,
}

export const query = graphql`
  query($id: String) {
    contentfulPolicyPages(id: { eq: $id }) {
      updatedAt(formatString: "MM/DD/Y")
      title
      content {
        raw
      }
    }
  }
`

export default PolicyPage
